#leaderboard {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#leaderboard table {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#name {
  text-align: left;
}

#wpm {
  text-align: right;
}
