* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: #130f40;

  color: #dff9fb;
}

.displayText {
  width: 70vw;
  display: flex;
  overflow: hidden;
  height: 10rem;
  font-size: 30px;
  position: absolute;
  top: 17rem;
  left: 15vw;
}
.text-tools {
  position: absolute;
  top: 23rem;
  left: 50vw;
}

.Article {
  position: relative;
}
