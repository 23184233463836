* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.Navbar {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 2rem 3rem;
  background: transparent;
  width: 100%;
  height: 10rem;
}

.Heading {
  font-size: 5rem;
  height: 100%;
  font-family: "Indie Flower", cursive;
  width: 31%;
  cursor: pointer;
  color: #ecf0f1;
  text-shadow: 5px 5px 2px #000;
  transition: ease-in-out linear;
}

.tools {
  width: 35%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100%;
  margin-bottom: 10px;
  padding-bottom: 15px;
}

.tools > div {
  height: 30px;
  margin: 0 1rem;
  font-family: "Courier New", Courier, monospace;
  font-size: 2rem;
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;
}

.btn {
  color: #dff9fb;
}

.count-of-words {
  color: #dff9fb;
  width: 20%;
  height: 30px;
  margin: 0 1rem;
  font-family: "Courier New", Courier, monospace;
  font-size: 1rem;
  position: relative;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
}

.set-count {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.active-count {
  color: chartreuse;
}

.word-count:hover,
.btn:hover,
.text-tools:hover {
  color: #ff7979;
}
