.data {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 19rem;
  justify-content: space-around;
}
table {
  padding: 5px;
  background-color: black;
  width: 23%;
  border: 2px solid white;
}
.clickPage > p {
  font-family: "Indie Flower", cursive;
}
