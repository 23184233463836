. {
  background-color: white;
}
.clickPage {
  width: 100%;
  height: 100%;
}

.button {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 80%;
  padding: 20px;
  text-align: center;
  outline: none;
}
